import React from 'react';
import { Header, Item, ItemList, Layout, SEO } from '../components';
import PageProps from '../models/PageProps';
import { blogToItem } from '../utils/contentList';

const CategoryPage = ({ pathContext }: PageProps) => {
  const { posts } = pathContext || {};

  return (
    <Layout>
      <SEO isPost={false} titlePrefix={'Blog | '} />
      <Header title='Blog' icon='document-edit' subHeading='Engineering & UX Blog' />
      <ItemList>
        {(posts || []).map((post) => blogToItem({node: post})).map((blog, idx) => (
          <Item key={idx} {...blog} />
        ))}
      </ItemList>
    </Layout>
  );
};
export default CategoryPage;
